@font-face {
  font-family: "Circular Std Book";
  src: url("assets/@font-face/860c3ec7bbc5da3e97233ccecafe512e.eot");
  src: url("assets/@font-face/860c3ec7bbc5da3e97233ccecafe512e.eot?#iefix") format("embedded-opentype"),
    url("assets/@font-face/860c3ec7bbc5da3e97233ccecafe512e.woff2") format("woff2"),
    url("assets/@font-face/860c3ec7bbc5da3e97233ccecafe512e.woff") format("woff"),
    url("assets/@font-face/860c3ec7bbc5da3e97233ccecafe512e.ttf") format("truetype"),
    url("assets/@font-face/860c3ec7bbc5da3e97233ccecafe512e.svg#Circular Std Book") format("svg");
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  background: #ccc;
  font-family: "Circular Std Book", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #71748d;
  background-color: #FFFF;
  -webkit-font-smoothing: antialiased;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
}

* {
  box-sizing: border-box;
}

input:focus,
select:focus,
button:focus {
  -webkit-box-shadow: 0px 0px 5px 0px #7a80b4 !important;
  -moz-box-shadow: 0px 0px 5px 0px #7a80b4 !important;
  box-shadow: 0px 0px 5px 0px #7a80b4 !important;
  outline: none !important;
  border: 1px solid #7a80b4 !important;
}

button.btnRemoveFilter {
  color: rgb(64, 68, 82);
  background-color: rgb(255, 255, 255);
  border-color: #ced4da;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border-radius: var(--sail-radius);
  border-radius: 6px;
}

button.btnConfirmFilter {
  color: white;
  background-color: rgb(255, 255, 255);
  border-color: #007bff;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  box-shadow: rgb(64 68 82 / 8%) 0px 2px 5px 0px;
  border-radius: var(--sail-radius);
  border-radius: 6px;
  background-color: #007bff;

}

button.btnFilter {
  color: #6c757d;
  background-color: #fff;
  border-color: #ced4da;
  padding-top: 0.1rem;
  margin-right: 2rem !important;
  padding-bottom: 0.1rem;
}

button.btnDownload {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

button.btnPrimary {
  color: #fff;
  background-color: #5969ff;
  border-color: #5969ff;
}

button.btnDownload:hover {
  color: #fff;
  background-color: #626b72;
  border-color: #626b72;
}

button.btnPrimary:hover {
  color: #fff;
  background-color: #4656e9;
  border-color: #4656e9;
}

.btnPrimary:focus,
.btnPrimary.focus,
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #4656e9 !important;
  border-color: #4656e9 !important;
  box-shadow: 0 0 0.1rem 0.1rem #b0b6ec !important;
}

button {
  cursor: pointer;
}

button.btnSecondary {
  color: #fff;
  background-color: #ff407b;
  border-color: #ff407b;
}

button.btnSecondary:hover {
  color: #fff;
  background-color: #f0346e;
  border-color: #f0346e;
}


button.btnConfirmFilter:hover {
  color: #fff;
}

.btnSecondary.focus,
.btnSecondary:focus,
.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #f0346e !important;
  border-color: #ff407b !important;
  box-shadow: 0 0 0.1rem 0.1rem #ff8e99 !important;
}

:root {
  --navbar-height: 68px;
  --window-bar-height: 1rem;
  --texte-MENU-side-menu: #a4aadb;
  --background-hover-side-menu: #24274a;
  --liens-side-menu: #7a80b4;
  --texte-menu-navbar: #82849f;
  --texte-dropdown-navbar: #686972;
  --texte-bleu-fonce: #3d405c;
  --texte-table: #71748d;
  --texte-navbar-hover: #5969ff;
  --bleu-fonce: #0e0c28;
  --gris-bleu: #efeff6;
  --border-separation: #e0e4ef;
  --breadcrum-text: #a6a6b7;
  --breadcrum-actif: #71728e;
  --border-navbar: #e6e6f2;
}